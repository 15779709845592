import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
}

export const ServicesWall = memo(function ServicesWall({ services }: Props) {
  if (!services) {
    return null
  }

  return (
    <Container row stretch tag="section" wrap>
      {services.map((item, index) => (
        <Service key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 7.5rem auto;

  @media (max-width: 1023px) {
    margin: 5.625rem auto;
  }
`
