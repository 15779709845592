import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  label?: string
}

export const Service = memo(function Service({
  description,
  image,
  label,
}: Props) {
  const [ref, isVisible] = useInView({ threshold: 0.25, triggerOnce: true })
  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      <LeftSide>
        {label ? <Title>{label}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </LeftSide>
      <RightSide>{image ? <LazyLoadImage {...image} /> : null}</RightSide>
    </Container>
  )
})

const Container = styled(motion.div)`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 50%;
  background: #53a78b;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  &:nth-of-type(4n + 3),
  &:nth-of-type(4n + 4) {
    > div {
      &:first-of-type {
        order: 2;
        &:before {
          right: 90%;
          left: -150px;
        }
      }
    }
  }
  &:nth-of-type(3n + 2),
  &:nth-of-type(8n + 3) {
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
  }

  @media (max-width: 1023px) {
    width: 100%;
    &:nth-of-type(odd) {
      background: #53a78b !important;
    }
    &:nth-of-type(even) {
      background: ${({ theme }) =>
        theme.colors.variants.neutralDark4} !important;
    }
    &:nth-of-type(4n + 3),
    &:nth-of-type(4n + 4) {
      > div {
        &:last-of-type {
          order: 2;
        }
      }
    }
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding: 5.75rem 4.514vw;
  position: relative;

  &:before {
    content: '';
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    position: absolute;
    top: 50%;
    left: 90%;
    right: -150px;
    z-index: 3;
    transform: translateY(-50%);
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3rem 1.9375rem;

    &:before {
      content: none;
    }
  }
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 1.8125rem;
`

const Description = styled.div`
  margin-top: 0.9375rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.75rem;
`

const RightSide = styled.div`
  width: 50%;
  padding-bottom: 60%;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 50%;
  }
`
